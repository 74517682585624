import React, { useState } from "react"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"

import {
  Wrapper,
  UpdateSubscriptionWrapper,
  UpdateSubscriptionForm,
  InputWrapper,
  ButtonWrapper,
} from "./ModifierAbonnement.module.css"
import { OrangeTitle } from "./../../../../css/Titre.module.css"
import { PaddingBottom10 } from "./../../../../css/Padding.module.css"
import "./../../../../css/Utils.css"
import Button from "./../../../../components/Button"
import Select from "./../../../../components/Select"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import firebase from "gatsby-plugin-firebase"

import {
  convertDateToTimestamp,
  convertTimestampToDate,
} from "./../../../../utils/date"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ModifierAbonnement = ({ subscription }) => {
  const [successSnackbarIsOpened, setSuccessSnackbarIsOpened] = useState(false)
  const [errorSnackbarIsOpened, setErrorSnackbarIsOpened] = useState(false)

  const [value, onChange] = useState(
    convertTimestampToDate(subscription.end / 1000)
  )
  const [type, setType] = useState(subscription.type)
  const types = ["freemium", "monthly", "yearly", "admin"]

  const renderTypeOfSubscription = type => {
    switch (type) {
      case "freemium":
        return "Freemium"
      case "monthly":
        return "Mensuel"
      case "yearly":
        return "Annuel"
      default:
        return "Admin"
    }
  }

  const handleSuccessClose = () => {
    setSuccessSnackbarIsOpened(false)
  }

  const handleErrorClose = () => {
    setErrorSnackbarIsOpened(false)
  }

  const handleChangeSubscription = () => {
    let newSubscription = {
      type: type,
      start:
        convertDateToTimestamp(
          convertTimestampToDate(subscription.start / 1000)
        ) * 1000,
      end: convertDateToTimestamp(value) * 1000,
    }
    const newUser = {
      subscription: newSubscription,
    }
    firebase
      .firestore()
      .collection("users")
      .doc(subscription.id)
      .update(newUser)
      .then(() => {
        setSuccessSnackbarIsOpened(true)
      })
      .catch(updatedUserError => {
        setErrorSnackbarIsOpened(true)
      })
  }

  return (
    <div className={Wrapper}>
      <h1 className={OrangeTitle}>Modifier Abonnement</h1>
      <div className={UpdateSubscriptionWrapper}>
        <div className={UpdateSubscriptionForm}>
          <div className={InputWrapper}>
            <div className={PaddingBottom10}>
              <label htmlFor="type">Type d'abonnement</label>
            </div>
            <Select
              placeholder="Sélectionnez un type d'abonnement"
              options={types.map(data => ({
                value: data,
                label: renderTypeOfSubscription(data),
              }))}
              onChange={e => setType(e.value)}
              value={{
                value: type,
                label: renderTypeOfSubscription(type),
              }}
            />
          </div>
          <div className="spacer"></div>
          <div className={InputWrapper}>
            <div className={PaddingBottom10}>
              <label htmlFor="date">Date</label>
            </div>
            <Calendar onChange={onChange} value={value} />
          </div>
        </div>
        <div className="row-wrapper">
          <div className="spacer"></div>
          <div className={ButtonWrapper}>
            <Button alt="" onClick={() => handleChangeSubscription()}>
              Valider
            </Button>
          </div>
        </div>
      </div>
      <Snackbar
        open={successSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleSuccessClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Bravo ! Tu as bien modifié cette offre !
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Oops... Les infos que tu as rentré ne sont pas correctes.
        </Alert>
      </Snackbar>
    </div>
  )
}

export default ModifierAbonnement
