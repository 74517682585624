import React, { useState, useEffect } from "react";
import { getUrlParams } from "./../../../utils/utils";
import Layout from "./../../../components/Layout";
import ModifierAbonnement from "./../../../nav/Admin/Utilisateurs/ModifierAbonnement";
import { Helmet } from "react-helmet";
import firebase from "gatsby-plugin-firebase";
import { getUser } from "./../../../auth";
import Error403 from "../../../nav/Error403";
import { TailSpin } from "react-loader-spinner";

const HandleUserStates = ({ user, subscription }) => {
  if (user === undefined) {
    return (
      <div style={{ textAlign: "center" }}>
        <TailSpin color="#FF8000" height={200} width={200} />
      </div>
    );
  } else if (user && user.subscription.type === "admin") {
    return <ModifierAbonnement user={user} subscription={subscription} />;
  } else {
    return <Error403 />;
  }
};

const ModifierAbonnementPage = () => {
  const [user, setUser] = useState(undefined);
  const [subscription, setSubscription] = useState(undefined);

  useEffect(() => {
    getUser(setUser);
    const id = getUrlParams("id");
    firebase
      .firestore()
      .collection("users")
      .doc(id)
      .get()
      .then((doc) => {
        setSubscription({
          type: doc._delegate._document.data.value.mapValue.fields.subscription
            .mapValue.fields.type.stringValue,
          start:
            doc._delegate._document.data.value.mapValue.fields.subscription
              .mapValue.fields.start.integerValue,
          end: doc._delegate._document.data.value.mapValue.fields.subscription
            .mapValue.fields.end.integerValue,
          id: doc.id,
        });
      })
      .catch((error) => {
        //console.log("Error getting document:", error)
      });
  }, []);

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Modifier abonnement</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-30 ans. Tu trouveras ici un formulaire pour modifier l'abonnement d'un utilisateur."
        />
        <meta
          name="keywords"
          content="réductions,jeunes,admin,abonnement,utilisateur"
        />
        <link
          rel="canonical"
          href="https://wynit.com/admin/utilisateurs/modifier-abonnement"
        />
      </Helmet>
      <HandleUserStates user={user} subscription={subscription} />
    </Layout>
  );
};

export default ModifierAbonnementPage;
